import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';

const NikkeGuidesHarmony: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Harmony Cubes - information</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_cubes.png"
            alt="Harmony Cubes - information"
          />
        </div>
        <div className="page-details">
          <h1>Harmony Cubes - information</h1>
          <h2>All you need to know about Harmony Cubes in NIKKE.</h2>
          <p>
            Last updated: <strong>15/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <StaticImage
          src="../../../images/nikke/generic/cube_view.jpg"
          alt="Guide"
        />
        <p>
          Harmony Cubes are a piece of equipment your NIKKEs can wear and only
          one Cube can be equipped at the same time - though once you upgrade
          the Cube, you are able to equip it on multiple NIKKEs.
        </p>
        <p>
          Currently there are 8 available different Harmony Cubes, each with an
          unique buff that improves one of the NIKKEs stats.
        </p>
        <h5>How to unlock Harmony Cubes?</h5>
        <p>
          To unlock the Harmony Cubes you need do conquer the Lost Sectors.
          Here's the order you will unlock them:
        </p>
        <ul>
          <li>
            <strong>Assault Cube</strong> - Lost Sector 1
          </li>
          <li>
            <strong>Onslaught Cube</strong> - Lost Sector 2
          </li>
          <li>
            <strong>Resilience Cube</strong> - Lost Sector 4
          </li>
          <li>
            <strong>Bastion Cube</strong> - Lost Sector 7
          </li>
          <li>
            <strong>Adjutant Cube</strong> - Lost Sector 9
          </li>
          <li>
            <strong>Wingman Cube</strong> - Lost Sector 11
          </li>
          <li>
            <strong>Quantum Cube</strong> - Lost Sector 14
          </li>
          <li>
            <strong>Vigor Cube</strong> - Lost Sector 18
          </li>
          <li>
            <strong>Endurance Cube</strong> - Lost Sector 20
          </li>
          <li>
            <strong> Healing Cube</strong> - Lost Sector 21
          </li>
          <li>
            <strong>Tempering Cube</strong> - Lost Sector 22
          </li>
          <li>
            <strong>Relic Assist Cube</strong> - Lost Sector 23
          </li>
          <li>
            <strong>Destruction Cube</strong> - Lost Sector 25
          </li>
        </ul>
        <h5>How to upgrade the Cubes?</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_upgrade.jpg"
          alt="Guide"
        />
        <p>
          To upgrade the Harmony Cubes you will need special items called
          Batteries. Each Cube needs different Batteries to upgrade it and you
          can obtain them either from Lost Sector or from the Union Shop.
        </p>
        <SectionHeader title="Harmony Cubes" />
        <p>Here's a list of the available Harmony Cubes:</p>
        <h6>Assault Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_1_sm.png"
          alt="Guide"
        />
        <p>
          Assault Cube increases the character's <strong>Hit Rate</strong> and
          starting from level 5 also gives additional damage as strong element.
        </p>
        <p>
          This Cube is suited best for characters that use SMGs or Shotguns as
          they benefit the most out of the buff. Also, Modernia can abuse the
          cube to get near-permanent uptime on her S2 ATK buff (but if you have
          Overload gear with Hit Rate, you don't need the Cube anymore).
        </p>
        <p>A few example characters that use the Assault Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
          <NikkeCharacter mode="icon" slug="drake" enablePopover />
          <NikkeCharacter mode="icon" slug="guilty" enablePopover />
        </div>
        <p>*Modernia loves Assault Cube until you get overload gear on her.</p>
        <hr />
        <h6>Onslaught Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_2_sm.png"
          alt="Guide"
        />
        <p>
          Onslaught Cube increases the character's{' '}
          <strong>Charge Damage</strong> and starting from level 5 also gives
          additional damage as strong element.
        </p>
        <p>
          This Cube is suited best for characters that use Sniper Rifles or
          Rocket Launchers as those weapons needs to charge their shots, so the
          cube gives them more damage.
        </p>
        <p>A few example characters that use the Onslaught Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="snow-white" enablePopover />
          <NikkeCharacter mode="icon" slug="harran" enablePopover />
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
        </div>
        <hr />
        <h6>Resilience Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_3_sm.png"
          alt="Guide"
        />
        <p>
          Resilience Cube increases the character's{' '}
          <strong>Reload Speed</strong> and starting from level 5 also gives
          additional damage as strong element.
        </p>
        <p>
          This Cube is suited best for characters that have long reload speed
          animation and the cube lowers their downtime by quite a lot - and this
          translates to a DPS increase. Also, characters that use the last
          bullet mechanic benefit from the cube a lot.
        </p>
        <p>
          Overall this is the most universal Cube that works on most characters.
        </p>
        <p>A few example characters that use the Resilience Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
          <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
          <NikkeCharacter mode="icon" slug="drake" enablePopover />
        </div>
        <hr />
        <h6>Bastion Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          Bastion Cube <strong>recovers the ammo while you're shooting</strong>{' '}
          and starting from level 5 also gives additional damage as strong
          element.
        </p>
        <p>
          This Cube is suited best for DPS characters who use Assault Rifle or
          Machine Guns as the ammo recovery allows them to keep shooting for
          longer before needing to reload which translates to increased DPS. At
          level 1, every 10 bullets you fire will magically 'load' your magazine
          with 1 bullet - while this doesn't sound that great, once you level up
          the Cube, the recovered ammo amount increases to 3 bullets per 10
          shots fired.
        </p>
        <p>A few example characters that use the Bastion Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="modernia" enablePopover />
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
          <NikkeCharacter mode="icon" slug="soda" enablePopover />
        </div>
        <hr />
        <h6>Adjutant Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_5_sm.png"
          alt="Guide"
        />
        <p>
          Adjutant Cube increases the character's{' '}
          <strong>Charging Speed</strong> and starting from level 5 also gives
          additional damage as strong element.
        </p>
        <p>
          This Cube is very similar to Onslaught Cube and it's suited best for
          characters that use Sniper Rifles or Rocket Launchers as those weapons
          needs to charge their shots, so the cube allows them to do it faster.
          Though in most cases you still prefer to use the Onslaught Cube as it
          has better numbers.
        </p>
        <p>
          Overall, the Cube is more popular in PVP because there you need to
          reach first Burst before the enemy to win.
        </p>
        <p>A few example characters that use the Adjutant Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
          <NikkeCharacter mode="icon" slug="centi" enablePopover />
          <NikkeCharacter mode="icon" slug="miracle-fairy-anne" enablePopover />
        </div>
        <hr />
        <h6>Wingman Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_6_sm.png"
          alt="Guide"
        />
        <p>
          Wingman Cube increases the character's <strong>Magazine Size</strong>{' '}
          and starting from level 5 also gives additional damage as strong
          element.
        </p>
        <p>
          This Cube is good generic cube as nearly everyone benefits from larger
          magazine size (only characters that rely on last bullet mechanic
          should stay away from it). Sadly, while initially the Cube sounded
          great, once Overload gear came into play, a lot of players turned away
          from the Wingman Cube and went back to Resilience/Bastion Cubes. Why?
          Because Overload gear can give you much much more Max Ammo than the
          Wingman Cube while the benefits from Bastion cube can't be replaced by
          anything and Resilience Cube has more uses too.
        </p>
        <p>A few example characters that use the Wingman Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="guilty" enablePopover />
          <NikkeCharacter mode="icon" slug="liter" enablePopover />
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
        </div>
        <hr />
        <h6>Quantum Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_7_sm.png"
          alt="Guide"
        />
        <p>
          Burst generation booster. Indispensable in PvP, allowing some teams to
          reach Full Burst earlier in some situations. For more information:{' '}
          <Link to="/nikke/guides/pvp-burst">here</Link>.
        </p>
        <p>A few example characters that use the Quantum Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="jackal" enablePopover />
          <NikkeCharacter mode="icon" slug="anis" enablePopover />
          <NikkeCharacter mode="icon" slug="centi" enablePopover />
        </div>
        <hr />
        <h6>Vigor Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_8_sm.png"
          alt="Guide"
        />
        <p>
          Relic Vigor Cube increases the character's Max HP (Maximum Hit Points)
          and starting from level 5 also gives additional damage as strong
          element.
        </p>
        <p>
          This Cube is suited best for characters that either use Max HP to buff
          their attack or healing, or for popular PvP Nikkes, as HP can matter a
          lot in that gamemode.
        </p>
        <p>A few example characters that use the Vigor Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="2b" enablePopover />
          <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
          <NikkeCharacter mode="icon" slug="noise" enablePopover />
        </div>
        <hr />
        <h6>Endurance Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_9_sm.png"
          alt="Guide"
        />
        <p>The Endurance Cube boosts DEF Stat of the nikke that equips it.</p>
        <p>
          The cube can be thought of as a weaker version of Vigor Cube, but not
          as a replacement. No Nikkes essentially scale off of DEF, so it's use
          is limited. It can be used to assist in surviving chip damage against
          opponents, especially in PvP, where tankers can benefit from it.
          Ideally put it on Defender type nikkes, since they scale the best with
          this cube.
        </p>
        <p>A few example characters that use the Endurance Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="moran" enablePopover />
          <NikkeCharacter mode="icon" slug="noah" enablePopover />
          <NikkeCharacter mode="icon" slug="anis" enablePopover />
        </div>
        <hr />
        <h6>Healing Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_10_sm.png"
          alt="Guide"
        />
        <p>The Healing Cube boosts 'HP Potency' of the nikke that equips it.</p>
        <p>
          If equipped by a Nikke that heals, boosts Heal Provided by that Nikke.
          EN translators just LOVE to spread misinformation. This cube does NOT
          provide HP Potency buff. The cube basically increases the healing
          given by a Nikke to her allies, or to herself. So at lvl1, if a nikke
          receives 100 heal from Rapunzel every shot she makes, the 8.08% buff
          leads to extra 8HP of healing, so Rapunzel will heal 108HP every shot
          with the cube! Hence, this cube can be thought of as a very decent
          cube for pure healers such as Rapunzel, S.Mary, Marciana or even Helm.
          It may also find proper use in PvP stall teams, where it can prove to
          be relentless and can allow certain nikkes to tank more than they
          could before by boosting the heal they receive in stall-type teams.
          Hence, tankers in PvP appreciate this cube.
        </p>
        <p>A few example characters that use the Healing Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
          <NikkeCharacter mode="icon" slug="quiry" enablePopover />
          <NikkeCharacter mode="icon" slug="noise" enablePopover />
        </div>
        <hr />
        <h6>Relic Tempering Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_11_sm.webp"
          alt="Guide"
        />
        <p>Decreases Damage taken by Nikkes who have this cube equipped.</p>
        <p>
          The absolute best cube for your Nikkes who are tanking damage! Immense
          PvP potential, can allow for better survivability for your tankers.
          Can allow units with cube to survive certain AoE or single target
          nukes they would be killed from if not for this cube! This cube's
          effect is additive with other damage taken or damage reduction
          effects, and can be effectively used with units like Moran to shut
          down teams focused on one unit in PvP.
        </p>
        <p>A few example characters that use the Relic Tempering Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="noise" enablePopover />
          <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
          <NikkeCharacter mode="icon" slug="noah" enablePopover />
        </div>
        <hr />
        <h6>Relic Assist Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_12_sm.webp"
          alt="Guide"
        />
        <p>Not an ideal cube to use.</p>
        <p>
          Sure it will give you an "emergency" hp buff when your HP is dropped
          below 20%, but it's insignificant and the cooldown between heal is
          horribly long (20s at least). Slap it on non-DPS Nikkes who cannot
          heal themselves for more stat and nothing else.
        </p>
        <p>A few example characters that use the Relic Assist Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="volume" enablePopover />
          <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
          <NikkeCharacter mode="icon" slug="liter" enablePopover />
        </div>
        <h6>Destruction Cube</h6>
        <StaticImage
          src="../../../images/nikke/generic/cube_13_sm.webp"
          alt="Guide"
        />
        <p>
          This cube provides the wearer with additional Parts Damage, which
          boosts their damage when attacking parts (extensions of a Rapture's
          body, such as Alteisen's turrets or Chatterbox's missile containers).
          This helps accelerate part destruction and even boost the team's total
          damage if the parts have a high uptime. One example is new
          Blacksmith's permanent core in the middle that spawns after both guns
          are destroyed.
        </p>
        <p>
          However, it also comes with an obvious drawback. Against bosses
          without parts or in PvP, it is simply useless and is only a stat
          stick. You still benefit from the ELE DMG, but that's it. Investing in
          it means you agree to the terms & policies that your Cube is not
          universal.
        </p>
        <p>
          In conclusion, this Cube has the potential to boost your team output
          (may be a significant difference in Raids), but it's limited to bosses
          with sturdy parts.{' '}
        </p>
        <p>A few example characters that use the Destruction Cube:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="asuka-shikinami-langley-wille"
            enablePopover
          />
        </div>
        <p>
          To get more information about the Cubes and the bonuses they give,
          check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Harmony Cubes - data"
            link="/nikke/guides/harmony-cubes-data"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_cubedata.png"
                alt="Harmony Cubes - data"
              />
            }
          />
        </Row>
        <SectionHeader title="Best Cubes" />
        <p>
          The cubes that are currently worth investing in right now are
          primarily <strong>Bastion and Resilience</strong>, followed by{' '}
          <strong>Quantum and Adjutant</strong> for PvP purposes. The universal{' '}
          <strong>Relic Vigor</strong> is also useful in situations where the
          extra HP is meaningful, especially for units who rely on MAX HP to
          boost their DPS or healing (such as 2B, Rapunzel, etc). In PvP, the
          extra HP can also be a clutch that determines the outcome of a battle.{' '}
          <strong>Onslaught</strong> may be the best-in-slot for some Nikkes,
          but since it is very niché, it is hard to justify buying their
          batteries. As for Assault, there is currently no benefit of upgrading
          it even for less accurate weapons as it is outclassed by many others.
        </p>
        <ul>
          <li>
            <strong>Bastion</strong>: Generally the best cube in the game for
            maximizing uptime, slightly better than Resilience, especially on
            Nikkes with a high max ammo count. However, Bastion is
            counterintuitive for units whose mechanics revolve around last
            bullet. Bastion scales rapidly and is only better than its rival,
            Resilience, starting from Level 7. This is because every reload
            comes with a constant frame time cost, which means more reload =
            more time wasted.
          </li>
          <li>
            <strong>Resilience</strong>: The Bastion's counterpart that is very
            comfortable to use on weapons with low ammo count (less than
            &lt;10), such as unbuffed Sniper Rifles, Shotguns, and Rocket
            Launchers. Resilience is suitable for units that rely on last ammo
            and can be considered over Bastion when a team is all about Reload
            Speed. This is because Reload Speed becomes more beneficial the
            closer it is to 100 (or 109%)
          </li>
          <li>
            <strong>Adjutant</strong>: Despite its relatively low values, the
            extra Charge Speed may be clutch in PvP to increase the chance of
            enemy RL whiffing. What this does is basically slow down the enemy's
            burst generation speed, while slightly speeding up your Full Burst
            generation (in very specific cases). However, it only works when you
            equip it on an RL wielder. In PvE, Adjutant can be used to reach
            certain breakpoints or boost damage on certain Nikkes (like Red
            Hood).
          </li>
          <li>
            <strong>Quantum:</strong> A prominent cube for PvP that establishes
            the standards of endgame team-building. It allows a slower team to
            reach Full Burst faster in some situations, significantly improving
            its likelihood of winning. It is more reliable than Adjutant, but it
            will only be useful for PvP, nowhere else.
          </li>
          <li>
            <strong>Relic Vigor</strong>: Makes your team tankier, makes your
            healing stronger, or makes your 2B deal more damage. Relic Vigor is
            a universal cube that can help improve survivability, but it doesn't
            offer much in terms of boosting damage, except on one specific unit.
            The extra HP might be the reason your Rapunzel survives a Scarlet
            wipe in the arena! Relic Vigor can also be used to manipulate which
            unit would receive Indomitability from Blanc when linked with Jackal
            in Arena.
          </li>
          <li>
            <strong>Relic Tempering</strong>: Makes your team tankier, which is
            extremely powerful on most important units like Noah, Noise,
            Rapunzel whose burst can turn the entire fight. Combining it with
            even SR Doll lv 0 and your PVP team becomes super solid with just
            this cube.
          </li>
        </ul>
        <SectionHeader title="Which Cube to upgrade first?" />
        <p>
          With the way the <strong>Bastion Cube</strong> scales, a lot of
          endgame players invest into it first - at least till they reach level
          7 when you unlock the 3 bullets refund per 10 shots fired.
        </p>
        <p>
          <strong>Resilience Cube</strong> while initially being better than
          Bastion Cube as the Reload Speed bonus is pretty massive, starts to
          fall off once you take into account the benefits from Bastion Cube and
          Overload Gear (you can get up to 85% Max Ammo per gear piece there
          which combined with the Bastion ammo refund allows some characters to
          shoot non-stop - like Modernia for example).
        </p>
        <p>So what should you do?</p>
        <p>
          Upgrading the Resilience Cube to level 3 to get the 22% Reload Speed
          increase (compared to 14% on earlier levels) is a pretty big jump, so
          it's worth to get it. But then you should turn your focus on the
          Bastion Cube and upgrade it all the way to level 7. Only then you can
          either return to Resilience Cube or start working on Wingman Cube (but
          imo it's still better to continue working on Bastion and Resilience
          Cubes instead).
        </p>
        <SectionHeader title="Bastion vs Resilience Cube" />
        <p>
          If you compare Bastion and Resilience Cubes on the same level, in most
          cases you will get nearly exact DPS increase - but this is only true
          whenever you compare the characters with only the Cubes equipped. When
          other buffs and most importantly, the Overload gear are added to the
          equation, the Bastion Cube starts to take over the Resilience Cube.
        </p>
        <p>Why does it happen?</p>
        <p>
          Because when you keep stacking Reload Speed you quickly start to hit
          diminishing returns and there's a lot of sources of Reload Speed buffs
          (Privaty, Admi for example). While currently there's nothing in the
          game that can replicate the benefits gained from the Bastion Cube -
          the ammo refund.
        </p>
        <p>TL;DR:</p>
        <ul>
          <li>
            Resilience reduces downtime, which means more bullets fired within a
            time window,
          </li>
          <li>
            Bastion increases uptime, which means more bullets fired before a
            reload.
          </li>
        </ul>
        <p>
          If you want to learn what characters should use which cube, check our
          other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Harmony Cubes - suggestions"
            link="/nikke/guides/harmony-cubes-suggestions"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_cubestips.png"
                alt="Harmony Cubes - suggestions"
              />
            }
          />
        </Row>
        <SectionHeader title="Video guide" />
        <p>If you want to learn more about Harmony Cubes check this video:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="XOt2O1avPtU" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesHarmony;

export const Head: React.FC = () => (
  <Seo
    title="Harmony Cubes - information | NIKKE | Prydwen Institute"
    description="All you need to know about Harmony Cubes in NIKKE."
    game="nikke"
  />
);
